@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

.auth-wrapper {
  box-sizing: border-box;
  background: #07a6d6 !important; /* #1c8ef9 blue that is from */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-wrapper h1,
.auth-wrapper h2,
.auth-wrapper h3,
.auth-wrapper h4,
.auth-wrapper h5,
.auth-wrapper h6,
.auth-wrapper label,
.auth-wrapper span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  margin-top: 3%;
}
@media only screen and (max-width: 490px) {
  .auth-inner {
    width: 300px;
  }
}
@media only screen and (max-width: 350px) {
  .auth-inner {
    width: 200px;
  }
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.make-white {
  color: white !important;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/*=======================  btns =========================*/

.logout-btn {
  border-width: 2px !important;
  color: #07a6d6 !important;
  border-color: #07a6d6 !important;
  border-radius: 0 !important;
}

.logout-btn:hover {
  background-color: #07a6d6 !important;
  color: white !important;
  border-color: #07a6d6 !important;
  border-radius: 0 !important;
}

.logout-btn:active,
.logout-btn:focus {
  background-color: #07a6d6 !important;
  color: white !important;
  border-color: #07a6d6 !important;
  border-radius: 100 !important;
  outline: none !important;
  box-shadow: 0 0 0 2px white !important;
  -webkit-box-shadow: 0 0 0 2px white !important;
  -moz-box-shadow: 0 0 0 2px white !important;
}
