.brk-btn {
  position: relative;
  background: none;
  color: #07a6d6;
  text-transform: uppercase;
  text-decoration: none;
  border: 0.2em solid #07a6d6;
  padding: 0.5em 1em;
  &:hover {
    background-color: #343a40;
    font-weight: bold;
  }
  &:active {
    outline: none !important;
    border: 0.2em solid #07a6d6 !important;
    box-shadow: 0 0 0 2px #07a6d6 !important;
    -webkit-box-shadow: 0 0 0 2px #07a6d6 !important;
    -moz-box-shadow: 0 0 2px 5px #07a6d6 !important;
  }
}