@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
/* =========== GENERAL STYLING =========== */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.member-form {
  height: 32px;
  width: 60%;
  min-width: 200px;
}
.site-color {
  color: #07a6d6;
}
.no-bullet-list {
  list-style-type: none;
}
.helper-btn {
  background-color: #07a6d6 !important;
  color: #343a40 !important;
}
.helper-btn:hover {
  background-color: #1d8eb1 !important;
}
/* ARROW BTN */
.arrow-btn {
  border: none;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  text-align: center;
  padding: 0 10px;
  color: #ffffff;
  background-color: #07a6d6;
  position: relative;
  display: inline-block;
}
.arrow-btn:after {
  position: absolute;
  left: -20px;
  content: " ";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 25px 20px 25px 0;
  border-color: transparent #07a6d6 transparent #07a6d6;
}
.arrow-btn:hover {
  background-color: #07789b;
}
.arrow-btn:hover:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 25px 20px 25px 0;
  border-color: transparent #07789b transparent #07789b;
}
.arrow-btn:active,
.arrow-btn:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  background-image: none !important;
  box-shadow: none !important;
}

/* =========== PROJECT COMPONENT =========== */
.project-input {
  padding: 10px;
}

.edit-proj-input {
  width: 40%;
  min-width: 200px;
}
.edit-proj-textarea {
  width: 70%;
  min-height: 120px;
  min-width: 200px;
}

.all-projects-header {
  text-decoration: underline;
  -webkit-text-decoration-color: #07a6d6;
          text-decoration-color: #07a6d6;
}

.project-create-form textarea,
.project-create-form input {
  display: block !important;
  width: 50% !important;
  max-width: 450px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: left;
}

/* =========== SPRINT COMPONENT =========== */

.sprint-form-create {
  display: block !important;
  width: 50% !important;
  max-width: 450px !important;
  text-align: left;
}

@media (max-width: 420px) {
  .create-sprint-full .label-sprint {
    font-size: 12px !important;
  }
  .create-sprint-full input {
    font-size: 12px !important;
  }
}

.edit-sprint-form {
  width: 80% !important;
  max-width: 210px !important;
  text-align: left;
}

.date-range-sprints input {
  display: block !important;
  width: 20% !important;
  max-width: 170px !important;
  text-align: left;
}

/* =========== TASK COMPONENT =========== */

.task-update-form textarea,
.task-update-form input {
  display: block !important;
  width: 100% !important;
  min-height: 38px;
  max-width: 450px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: left;
}

.task-update-form textarea {
  margin-top: 20px;
  height: 200px !important;
}

/* TASK CARD */

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.cards_item {
  display: flex;
  padding: 1rem;
  max-width: 420px;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    width: 33.3333%;
  }
}

.card {
  background: linear-gradient(to bottom left, #1786a8 40%, #07a6d6 100%);
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_content {
  padding: 1rem;
}

.card_title {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
}

.card_text {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  font-weight: 400;
}
.made_by {
  font-weight: 400;
  font-size: 13px;
  margin-top: 35px;
  text-align: center;
}

.card-color {
  background: linear-gradient(to bottom left, #7a7c7c 40%, #8f9091 100%);
}

.brk-btn{position:relative;background:none;color:#07a6d6;text-transform:uppercase;text-decoration:none;border:0.2em solid #07a6d6;padding:0.5em 1em}.brk-btn:hover{background-color:#343a40;font-weight:bold}.brk-btn:active{outline:none !important;border:0.2em solid #07a6d6 !important;box-shadow:0 0 0 2px #07a6d6 !important;-webkit-box-shadow:0 0 0 2px #07a6d6 !important;-moz-box-shadow:0 0 2px 5px #07a6d6 !important}

.auth-wrapper {
  box-sizing: border-box;
  background: #07a6d6 !important; /* #1c8ef9 blue that is from */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-wrapper h1,
.auth-wrapper h2,
.auth-wrapper h3,
.auth-wrapper h4,
.auth-wrapper h5,
.auth-wrapper h6,
.auth-wrapper label,
.auth-wrapper span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  margin-top: 3%;
}
@media only screen and (max-width: 490px) {
  .auth-inner {
    width: 300px;
  }
}
@media only screen and (max-width: 350px) {
  .auth-inner {
    width: 200px;
  }
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.make-white {
  color: white !important;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/*=======================  btns =========================*/

.logout-btn {
  border-width: 2px !important;
  color: #07a6d6 !important;
  border-color: #07a6d6 !important;
  border-radius: 0 !important;
}

.logout-btn:hover {
  background-color: #07a6d6 !important;
  color: white !important;
  border-color: #07a6d6 !important;
  border-radius: 0 !important;
}

.logout-btn:active,
.logout-btn:focus {
  background-color: #07a6d6 !important;
  color: white !important;
  border-color: #07a6d6 !important;
  border-radius: 100 !important;
  outline: none !important;
  box-shadow: 0 0 0 2px white !important;
  -webkit-box-shadow: 0 0 0 2px white !important;
  -moz-box-shadow: 0 0 0 2px white !important;
}

